import React from "react"
import PropTypes from "prop-types"
import { Col, Container, Row } from "react-bootstrap"
import { FaCheck } from "react-icons/fa"

function ListWithDescription({ items, heading }) {
  function getColClass(i) {
    if (i === items.length - 2) {
      return "mb-4 mb-md-0"
    } else if (i !== items.length - 1) {
      return "mb-4"
    }
  }

  return (
    <Container>
      <h2 className="text-center">{heading}</h2>
      <Row as="ul" className="list-unstyled mb-0">
        {items.map((item, i) => (
          <Col
            key={item.heading}
            as="li"
            xs={12}
            md={6}
            className={getColClass(i)}
          >
            <h3 className="h4 mb-2">
              <span className="mr-3">
                <FaCheck />
              </span>
              {item.heading}
            </h3>
            <p
              className="pb-0 mb-0"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

ListWithDescription.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      description: PropTypes.any.isRequired,
    })
  ).isRequired,
  heading: PropTypes.string.isRequired,
}

export default ListWithDescription
