import React from "react"
import MainLayout from "../layouts"
import { Col, Container, Row } from "react-bootstrap"
import FreeQuoteCard from "../components/FreeQuoteCard"
import { graphql } from "gatsby"
import { FaCheck } from "react-icons/fa"
import Img from "gatsby-image"
import ContactHeading from "../components/ContactHeading"
import ListWithDescription from "../components/ListWithDescription"

export const pageQuery = graphql`
  query {
    commercialBackgroundImage: file(
      relativePath: { eq: "commercial-background.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fixed(height: 366, width: 570) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    leoInVanWorkingImage: file(relativePath: { eq: "leo-in-van-working.jpg" }) {
      childImageSharp {
        fixed(height: 366, width: 570) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const servicesData = [
  {
    heading: "24/7 Emergency Lockout",
    description:
      "Locking your keys inside a room or premise of your business are unfortunate accidents that do happen so our technicians are always ready and prepared to attend to commercial establishments during a lockout. Our locksmiths are available for you 24/7, seven days a week.",
  },
  {
    heading: "Business Re-key",
    description: `We are your go to locksmiths for quality and affordable lock <a href="https://swift-locksmith.tumblr.com/post/189479238411/the-benefits-of-lock-rekeying" target="_blank">rekeying services</a> in Sacramento and the surrounding areas. We offer you a variety of commercial locks, standard locks and high security locks to ensure that you enhance the security of your business at an affordable price.`,
  },
  {
    heading: "New Commercial Lock Installation",
    description:
      "Our trained commercial locksmiths have all the proper tools to replace your locks so you can make sure your company stays protected. Whether you have a broken lock, need to upgrade your locks or moved into a new business, our locksmiths have you covered!",
  },
  {
    heading: "Aluminum Door Lock Alignment & Installation",
    description:
      "In addition to covering your lock and security needs, we offer aluminum door locks and alignment for your business. Our skilled technicians have the expertise to install door frames, locks, and handles for both exterior and interior doors. You can trust Swift Locksmith to get the job done right the first time.",
  },
  {
    heading: "Broken Key Extraction",
    description:
      "If you find yourself with a broken key or a lock jam at your business, give us a call right away. Our commercial locksmiths have been in this industry for over 10 years and have gained all the necessary skills and experience to get the job done properly and in a timely manner.",
  },
  {
    heading: "On-Site Key Duplication",
    description:
      "We come to you for your key-duplication needs and our job is to ensure that you get as many duplicate keys as needed. By using the latest technologies, we can make the keys very quickly. You can also customize the keys to your specifications.",
  },
]

function CommercialPage({ data }) {
  return (
    <MainLayout
      title="Commercial Locksmith Service & Security | 916-995-0135 | 24 Hr"
      description="We are your local commercial security expert. Great prices & fast installation services. High security locks, business re-key, on-site key duplication & more."
      offset
    >
      <ContactHeading
        background={data.commercialBackgroundImage.childImageSharp.fluid}
      >
        <h1>24 Hour Commercial Locksmith Services</h1>
      </ContactHeading>
      <Container as="section">
        <h2 className="text-center">Sacramento Commercial Locksmith</h2>
        <p className="indent">
          You can search all of Sacramento and not find a better or more
          professional commercial locksmith than Swift Locksmiths. Don't take
          our word for it, just read some of our reviews from over 100 customers{" "}
          <a
            href="https://www.yelp.com/biz/swift-locksmiths-sacramento"
            target="_blank"
          >
            here
          </a>
          .
        </p>
        <p className="indent">
          For the safety of your business and your own peace of mind, you know
          that you need to go with the best. You must carefully screen and
          choose the security providers that are capable of assessing your needs
          and recommending options for your business’ access and control
          systems.
        </p>
        <p className="indent">
          You need trustworthiness and discretion. You need locksmiths who
          arrive on time and work diligently to get your job done in the minimum
          amount of time, with the least disruption possible to your business
          and employees.
        </p>
      </Container>
      <section>
        <ListWithDescription
          items={servicesData}
          heading="Commercial Services"
        />
      </section>
      <Container as="section">
        <Row>
          <Col xs={12} md={6} className="mb-2 mb-md-0 col-sm-pad">
            <Img
              fixed={data.locksmithVanInCityImage.childImageSharp.fixed}
              alt="locksmith-van-in-city"
              style={{ maxWidth: "100%" }}
            />
          </Col>
          <Col xs={12} md={6} className="col-sm-pad">
            <Img
              fixed={data.leoInVanWorkingImage.childImageSharp.fixed}
              alt="leo-in-van-working"
              style={{ maxWidth: "100%" }}
            />
          </Col>
        </Row>
      </Container>
      <Container as="section" className="mb-5">
        <h2>Commercial Security Solutions</h2>
        <p className="indent">
          We are first rate professionals, and offer the best hardware and
          products. We deal in only products from quality manufacturers,
          including all the brand names you expect to see. We can discuss with
          you the various merits of different types of locks or systems and help
          you chose the most appropriate one. We pride ourselves in having
          impressive customer service, the type you tell your friends and
          colleagues about later.
        </p>
        <p className="indent">
          Our reputation is for both quality work and customer satisfaction, and
          you can depend on that being the case, every time. Swift Locksmiths is
          composed of fully licensed locksmiths and security technicians who use
          the most advanced and high-quality locksmith products to provide
          businesses and corporations with top-notch security and unshakable
          peace of mind. Every team member at Swift Locksmiths is not only
          skilled but committed to the customer’s safety, security, and
          satisfaction.
        </p>
        <strong>
          Call us now to find out our amazing discount offers and promotions we
          currently have and don’t forget, we not only install and repair locks,
          but also provide solutions for safes and security systems.
        </strong>
      </Container>
    </MainLayout>
  )
}

export default CommercialPage
